import PageBody from 'common/components/pageBody/PageBody';
import PageHeader from 'common/components/pageHeader/PageHeader';
import useTitle from 'common/hooks/useTitle';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ManagementPage.module.scss';
import SearchBar from 'common/components/searchBar/SearchBar';
import Label from 'common/components/label/Label';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import { toast } from 'react-hot-toast';
import PolesService from 'api/poles/PolesService';
import { PoleDto } from 'api/poles/models/PoleDto';
import { PoleServiceDto } from 'api/poles/models/PoleServiceDto';
import Button from 'common/components/button/Button';
import { useNavigate } from 'react-router-dom';
import Checkbox from 'common/components/checkbox/Checkbox';
import NoInfoToShow from 'common/components/noInfoToShow/NoInfoToShow';
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import PolesList from './polesList/PolesList';
import PoleServicesList from './poleServicesList/PoleServicesList';
import { reorder } from 'common/utils/reorder';
import { useDebouncedCallback } from 'use-debounce';
import { v4 as uuidv4 } from 'uuid';
import { PageBreakDirection } from './item/Item';
import PageBreakItem from './pageBreakItem/PageBreakItem';

const DEFAULT_PAGE_BREAK_DELAY = 15;

function filterPoles(poles: PoleDto[], searchValue: string) {
    let result = poles.filter(pole =>
        (pole.name ?? '').toLowerCase().includes(searchValue.toLocaleLowerCase())
        ||
        pole.services.find(service =>
            (service.name ?? '').toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
            || (service.code ?? '').toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
        )
    );
    result = result.map(pole => {
        return {
            ...pole,
            services: pole.services.filter(service =>
                (service.name ?? '').toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
                || (service.code ?? '').toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
            )
        }
    });
    return result;
}

const ManagementPage: React.FC = () => {
    const { t } = useTranslation();
    useTitle(t('management.page_title', { appName: t('app.name') }));
    const [searchValue, setSearchValue] = useState('');
    const [poles, setPoles] = useState<PoleDto[]>([]);
    const navigate = useNavigate();
    const [polesIdsToDelete, setPolesIdsToDelete] = useState<string[]>([]); // TODO: !
    const [polesServicesIdsToDelete, setPolesServicesIdsToDelete] = useState<string[]>([]); // TODO: !

    const [isSearch, setIsSearch] = useState<boolean>(false);

    const getData = async () => {
        try {
            Loading.show();

            const polesResult = await PolesService.getList({
                isVisibleOnly: false,
            });

            let result = polesResult;

            if (searchValue) {
                result = filterPoles(result, searchValue);
                setIsSearch(true);
            } else {
                setIsSearch(false);
            }

            setPoles(() => result);

            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get management data', error);
            toast.error(t('common.messages.error_load_info'));
            Loading.hide();
        }
    }

    const onTogglePoleIsOpen = (pole: PoleDto, isOpen: boolean) => {
        setPoles(poles => poles.map(p => {
            if (p.id === pole.id) {
                return { ...p, isOpen }
            }
            return p;
        }));
    }

    const onSelectPole = (pole: PoleDto, selected: boolean) => {
        setPoles(poles => poles.map(p => {
            if (p.id === pole.id) {
                return {
                    ...p,
                    visible: selected,
                    services: p.services.map(s => {
                        return { ...s, visible: selected }
                    }),
                }
            }
            return p;
        }));
    }

    const onSelectService = (pole: PoleDto, service: PoleServiceDto, selected: boolean) => {
        setPoles(poles => poles.map(p => {
            if (p.id === pole.id) {
                return {
                    ...p,
                    services: p.services.map(s => {
                        if (s.id === service.id) {
                            return { ...s, visible: selected }
                        }
                        return s;
                    }),
                }
            }
            return p;
        }));
    }

    const onSelectAll = (selected: boolean) => {
        setPoles(poles => poles.map(p => {
            return {
                ...p,
                visible: selected,
                services: p.services.map(s => {
                    return { ...s, visible: selected }
                }),
            }
        }));
    }

    const goBack = () => {
        navigate('/');
    }

    const processSave = async () => {
        try {
            await PolesService.update({
                poles,
                polesIdsToDelete,
                polesServicesIdsToDelete,
            });

        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get management data', error);
            throw error;
        }
    }

    const save = () => {
        return toast.promise(
            processSave(),
            {
                loading: t('common.saving'),
                success: t('common.messages.record_save_success'),
                error: t('common.messages.record_save_error'),
            }
        );
    }

    const onNewPolePageBreak = (pole: PoleDto, direction: PageBreakDirection) => {
        setPoles(poles => {
            const newPoles = [...poles];

            const pageBreak = {
                id: uuidv4(),
                services: [],
                order: 0, // Changed in the end
                visible: true,
                isPageBreak: true,
                pageBreakDelay: DEFAULT_PAGE_BREAK_DELAY,
                isFixed: false,
            };

            // Find new index
            const poleIndex = newPoles.findIndex(p => p.id === pole.id);
            let index = direction === PageBreakDirection.BEFORE ? poleIndex : poleIndex + 1;
            if (index < 0) {
                index = 0;
            }

            // Add to array in the right position
            newPoles.splice(index, 0, pageBreak);

            // Re order
            return newPoles.map((p, i) => ({ ...p, order: i }));
        });
    }

    const onNewServicePageBreak = (pole: PoleDto, service: PoleServiceDto, direction: PageBreakDirection) => {
        setPoles(poles => poles.map(p => {
            if (p.id === pole.id) {
                let newServices = [...p.services];

                const pageBreak: PoleServiceDto = {
                    id: uuidv4(),
                    order: 0, // Changed in the end
                    visible: true,
                    isPageBreak: true,
                    poleId: p.id,
                    pageBreakDelay: DEFAULT_PAGE_BREAK_DELAY,
                };

                // Find new index
                const serviceIndex = newServices.findIndex(s => s.id === service.id);
                let index = direction === PageBreakDirection.BEFORE ? serviceIndex : serviceIndex + 1;
                if (index < 0) {
                    index = 0;
                }

                // Add to array in the right position
                newServices.splice(index, 0, pageBreak);

                // Re order
                newServices = newServices.map((s, i) => ({ ...s, order: i }));

                return {
                    ...p,
                    isOpen: true,
                    services: newServices,
                };
            }
            return p;
        }));
    }

    const onRemovePolePageBreak = (pageBreak: PoleDto) => {
        setPoles(poles => {
            return poles.filter(p => p.id !== pageBreak.id).map((p, i) => ({ ...p, order: i }));
        });
        setPolesIdsToDelete(ids => ([...ids, pageBreak.id]));
    }

    const onRemovePoleServicePageBreak = (pole: PoleDto, pageBreak: PoleServiceDto) => {
        setPoles(poles => {
            return poles.map(p => {
                if (p.id === pole.id) {
                    return {
                        ...p,
                        services: p.services.filter(s => s.id !== pageBreak.id).map((s, i) => ({ ...s, order: i })),
                    };
                }
                return p;
            });
        });
        setPolesServicesIdsToDelete(ids => ([...ids, pageBreak.id]));
    }

    const onChangePolePageBreak = (pageBreak: PoleDto, pageBreakDelay: number) => {
        setPoles(poles => {
            return poles.map(p => {
                if (p.id === pageBreak.id) {
                    return {
                        ...p,
                        pageBreakDelay,
                    }
                }
                return p;
            });
        });
    }

    const onChangePoleServicePageBreak = (pole: PoleDto, pageBreak: PoleServiceDto, pageBreakDelay: number) => {
        setPoles(poles => {
            return poles.map(p => {
                if (p.id === pole.id) {
                    return {
                        ...p,
                        services: p.services.map(s => {
                            if (s.id === pageBreak.id) {
                                return {
                                    ...s,
                                    pageBreakDelay,
                                };
                            }
                            return s;
                        }),
                    }
                }
                return p;
            });
        });
    }

    function onDragEnd(result: DropResult) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        if (result.source.droppableId === 'POLES') {
            const newPoles = reorder(
                poles,
                result.source.index,
                result.destination.index
            ).map((p, i) => ({ ...p, order: i }));

            setPoles(newPoles);
        }
        else if (result.source.droppableId.startsWith('SERVICES')) {
            const poleId = result.source.droppableId.split('__')[1];

            setPoles(poles => poles.map(p => {
                if (p.id === poleId) {
                    return {
                        ...p,
                        services: reorder(
                            p.services,
                            result.source.index,
                            result.destination!.index
                        ).map((s, i) => ({ ...s, order: i })),
                    }
                }
                return p;
            }));
        }
    }

    useEffect(() => {
        void getData();
    }, []);

    const polesToShow = poles.filter(x => !x.isFixed);

    const allChecked = !polesToShow.find(x => !x.visible);

    const debounced = useDebouncedCallback(() => {
        void getData();
    }, 100);

    const lastPageBreak = poles.find(x => x.isFixed);

    return (
        <div>
            <PageHeader title={t('management.title')} />
            <PageBody>
                <div className={styles.header}>
                    <div className={styles.searchBarContainer}>
                        <SearchBar
                            value={searchValue}
                            onChange={e => setSearchValue(e)}
                            onSearch={getData}
                            onClear={() => {
                                setSearchValue('');
                                debounced();
                            }}
                            placeholder={t('management.search_placeholder')}
                            className={styles.searchBar}
                            totalResults={polesToShow.length}
                            showResults={isSearch}
                        />
                    </div>
                    <div className={styles.labelContainer}>
                        <Checkbox
                            checked={allChecked}
                            onChange={e => onSelectAll(e.target.checked)}
                        />
                        <Label className={styles.label}>{t('management.poles_services')}</Label>
                    </div>
                </div>

                {!polesToShow.length && <NoInfoToShow />}

                {Boolean(polesToShow.length) && (
                    <div>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <PolesList
                                poles={polesToShow}
                                onSelect={onSelectPole}
                                onToggleIsOpen={onTogglePoleIsOpen}
                                dragDisabled={isSearch}
                                onNewPageBreak={onNewPolePageBreak}
                                onRemovePageBreak={onRemovePolePageBreak}
                                onChangePageBreak={onChangePolePageBreak}
                            >
                                {(pole) => (
                                    <PoleServicesList
                                        pole={pole}
                                        onSelect={onSelectService}
                                        dragDisabled={isSearch}
                                        onNewPageBreak={onNewServicePageBreak}
                                        onRemovePageBreak={onRemovePoleServicePageBreak}
                                        onChangePageBreak={onChangePoleServicePageBreak}
                                    />
                                )}
                            </PolesList>
                        </DragDropContext>

                        {lastPageBreak && <PageBreakItem
                            key={lastPageBreak.id}
                            id={lastPageBreak.id}
                            index={99999}
                            pageBreakDelay={lastPageBreak.pageBreakDelay}
                            dragDisabled
                            disableContextMenu
                            isOutsideDraggable
                            onChange={(pageBreakDelay) => onChangePolePageBreak(lastPageBreak, pageBreakDelay)}
                        />}

                        <div className={styles.footerButtons}>
                            <Button colorType="outline" spaceRight onClick={goBack}>{t('common.go_back')}</Button>
                            <Button onClick={save}>{t('common.save')}</Button>
                        </div>
                    </div>
                )}

            </PageBody>
        </div>
    );
};

export default ManagementPage;

