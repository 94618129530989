import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './PolesTable.module.scss';
import { PoleDto } from 'api/poles/models/PoleDto';
import { hexToRgbA } from 'common/utils/hexToRgba';
import { FaMars, FaTransgender, FaVenus } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import { PoleServiceDto } from 'api/poles/models/PoleServiceDto';

export interface PolePageModel {
    delay: number;
    list: PoleModel[];
}

export type PoleModel = PoleDto & {
    isVirtual?: boolean;
    allServices: PoleServiceDto[];
}

type PlannedViewMode = '24' | '48' | '72';

const PLANNED_TOOLTIP_ID = 'planned-tooltip';

interface PoleElementProps {
    pole: PoleModel;
    plannedViewMode: PlannedViewMode;
}

const PoleElement: React.FC<PoleElementProps> = memo(({ pole, plannedViewMode }: PoleElementProps) => {
    const { t } = useTranslation();

    const numberBeds = pole.allServices.map(x => x.numberBeds ?? 0).reduce((t, c) => t + c, 0);
    const numberOccupiedBeds = pole.allServices.map(x => x.numberOccupiedBeds ?? 0).reduce((t, c) => t + c, 0);
    const numberAvailableBeds = pole.allServices.map(x => x.numberAvailableBeds ?? 0).reduce((t, c) => t + c, 0);
    const numberActualMale = pole.allServices.map(x => x.numberActualMale ?? 0).reduce((t, c) => t + c, 0);
    const numberActualFemale = pole.allServices.map(x => x.numberActualFemale ?? 0).reduce((t, c) => t + c, 0);
    const numberActualUndefined = pole.allServices.map(x => x.numberActualUndefined ?? 0).reduce((t, c) => t + c, 0);
    const numberPlannedEntry = pole.allServices.map(x => {
        if (plannedViewMode === '48') {
            return x.numberPlannedEntry48 ?? 0;
        }
        else if (plannedViewMode === '72') {
            return x.numberPlannedEntry72 ?? 0;
        }
        return x.numberPlannedEntry24 ?? 0;
    }).reduce((t, c) => t + c, 0);
    const numberPlannedExit = pole.allServices.map(x => {
        if (plannedViewMode === '48') {
            return x.numberPlannedExit48 ?? 0;
        }
        if (plannedViewMode === '72') {
            return x.numberPlannedExit72 ?? 0;
        }
        return x.numberPlannedExit24 ?? 0;
    }).reduce((t, c) => t + c, 0);

    return (
        <div className={styles.poleContainer}>
            {pole.services.filter(service => !service.isPageBreak).map((service, serviceIndex) => (
                <div
                    key={service.id}
                    className={`${styles.row} ${styles.rowData} ${serviceIndex === 0 && styles.rowBorder}`}
                    style={{
                        borderColor: serviceIndex === 0 ? service.color : undefined,
                        color: '#000000', // service.color,
                        backgroundColor: service.color ? hexToRgbA(service.color, 0.2) : undefined,
                    }}
                >
                    <div className={`${styles.col30} ${styles.serviceCol} ${styles.serviceColLabel}`}>
                        {service.name} <span>({service.code})</span>
                    </div>
                    <div className={`${styles.col15H2} ${styles.serviceCol}`}>
                        {service.numberBeds}
                    </div>
                    <div className={`${styles.col15H2} ${styles.serviceCol}`}>
                        {service.numberOccupiedBeds ?? '-'}
                    </div>
                    <div className={`${styles.col35H4} ${styles.serviceCol} ${styles.serviceColDarker}`}>
                        {service.numberAvailableBeds ?? '-'}
                    </div>
                    <div className={`${styles.col35H4} ${styles.serviceCol} ${styles.serviceColDarker}`}>
                        {service.numberActualMale}
                    </div>
                    <div className={`${styles.col35H4} ${styles.serviceCol} ${styles.serviceColDarker}`}>
                        {service.numberActualFemale}
                    </div>
                    <div className={`${styles.col35H4} ${styles.serviceCol} ${styles.serviceColDarker}`}>
                        {service.numberActualUndefined}
                    </div>
                    <div className={`${styles.col20H2} ${styles.serviceCol}`}>
                        <span
                            data-tooltip-id={PLANNED_TOOLTIP_ID}
                            data-tooltip-content={(plannedViewMode === '48'
                                ? service.nextPlannedEntry48 :
                                (plannedViewMode === '72' ? service.nextPlannedEntry72 : service.nextPlannedEntry24)) ?? '-'}
                        >
                            {plannedViewMode === '48'
                                ? service.numberPlannedEntry48 :
                                (plannedViewMode === '72' ? service.numberPlannedEntry72 : service.numberPlannedEntry24) }
                        </span>
                    </div>
                    <div className={`${styles.col20H2} ${styles.serviceCol}`}>
                        <span
                            data-tooltip-id={PLANNED_TOOLTIP_ID}
                            data-tooltip-content={(plannedViewMode === '48'
                                ? service.nextPlannedExit48 :
                                (plannedViewMode === '72' ? service.nextPlannedExit72 : service.nextPlannedExit24)) ?? '-'}
                        >
                            {plannedViewMode === '48'
                                ? service.numberPlannedExit48 :
                                (plannedViewMode === '72' ? service.numberPlannedExit72 : service.numberPlannedExit24)}
                        </span>
                    </div>
                </div>
            ))}
            {!pole.isVirtual && (
                <div
                    className={`${styles.row} ${styles.rowData}`}
                    style={{
                        backgroundColor: pole.color,
                    }}
                >
                    <div className={`${styles.col30} ${styles.poleCol} ${styles.poleColLabel}`}>
                        {t('home.table.total')} {pole.name}
                    </div>
                    <div className={`${styles.col15H2} ${styles.poleCol}`}>
                        {numberBeds}
                    </div>
                    <div className={`${styles.col15H2} ${styles.poleCol}`}>
                        {numberOccupiedBeds ?? '-'}
                    </div>
                    <div className={`${styles.col35H4} ${styles.poleCol} ${styles.polColDarker}`}>
                        {numberAvailableBeds ?? '-'}
                    </div>
                    <div className={`${styles.col35H4} ${styles.poleCol} ${styles.polColDarker}`}>
                        {numberActualMale}
                    </div>
                    <div className={`${styles.col35H4} ${styles.poleCol} ${styles.polColDarker}`}>
                        {numberActualFemale}
                    </div>
                    <div className={`${styles.col35H4} ${styles.poleCol} ${styles.polColDarker}`}>
                        {numberActualUndefined}
                    </div>
                    <div className={`${styles.col20H2} ${styles.poleCol}`}>
                        {numberPlannedEntry}
                    </div>
                    <div className={`${styles.col20H2} ${styles.poleCol}`}>
                        {numberPlannedExit}
                    </div>
                </div>
            )}
        </div>
    );
});

interface Props {
    poles: PoleModel[];
    isTv?: boolean;
}

const PolesTable: React.FC<Props> = ({ poles, isTv }: Props) => {
    const { t } = useTranslation();
    const [plannedViewMode, setPlannedViewMode] = useState<PlannedViewMode>('24');
    
    return (
        <div className={`${styles.container} ${!isTv && styles.web}`}>
            <div>
                <div className={`${styles.row} ${styles.rowHeader}`}>
                    <div className={`${styles.col45} ${styles.headerCol}`}></div>
                    <div className={`${styles.col35} ${styles.headerCol} ${styles.headerColDark}`}>{t('home.table.actual')}</div>
                    <div className={`${styles.col20} ${styles.headerCol} ${styles.plannedHeaderCol}`}>
                        <div className={styles.plannedTitle}>
                            {t('home.table.planned')}
                        </div>
                        <div className={styles.plannedButtons}>
                            <button
                                onClick={() => setPlannedViewMode('24')}
                                className={`${styles.plannedButton} ${plannedViewMode === '24' && styles.plannedButtonSelected}`}
                            >
                                24h
                            </button>
                            <button
                                onClick={() => setPlannedViewMode('48')}
                                className={`${styles.plannedButton} ${plannedViewMode === '48' && styles.plannedButtonSelected}`}
                            >
                                48h
                            </button>
                            <button
                                onClick={() => setPlannedViewMode('72')}
                                className={`${styles.plannedButton} ${plannedViewMode === '72' && styles.plannedButtonSelected}`}
                            >
                                72h
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`${styles.row} ${styles.rowHeader} ${styles.rowHeaderSub}`}>
                    <div className={`${styles.col30} ${styles.headerCol} ${styles.headerColDark} ${styles.headerColLabel}`}>
                        {t('home.table.label')}
                    </div>
                    <div className={`${styles.col15H2} ${styles.headerCol} ${styles.headerColDark}`}>
                        {t('home.table.open_beds')}
                    </div>
                    <div className={`${styles.col15H2} ${styles.headerCol} ${styles.headerColDark}`}>
                        {t('home.table.occupied_beds')}
                    </div>
                    <div className={`${styles.col35H4} ${styles.headerCol} ${styles.headerColDarker}`}>
                        {t('home.table.available_beds')}
                    </div>
                    <div className={`${styles.col35H4} ${styles.headerCol} ${styles.headerColDarker}`}>
                        <FaMars /> {t('home.table.males')}
                    </div>
                    <div className={`${styles.col35H4} ${styles.headerCol} ${styles.headerColDarker}`}>
                        <FaVenus /> {t('home.table.females')}
                    </div>
                    <div className={`${styles.col35H4} ${styles.headerCol} ${styles.headerColDarker}`}>
                        <FaTransgender /> {t('home.table.undefineds')}
                    </div>
                    <div className={`${styles.col20H2} ${styles.headerCol} ${styles.headerColDark}`}>
                        {t('home.table.planned_entry')}
                    </div>
                    <div className={`${styles.col20H2} ${styles.headerCol} ${styles.headerColDark}`}>
                        {t('home.table.planned_exit')}
                    </div>
                </div>
            </div>
            
            <div className={`${styles.body} ${!isTv && styles.web}`}>
                {poles.filter(pole => !pole.isPageBreak).map((pole) => <PoleElement key={pole.id} pole={pole} plannedViewMode={plannedViewMode} />)}
            </div>

            <Tooltip id={PLANNED_TOOLTIP_ID} />
        </div>
    );
};

export default PolesTable;
