import Http from 'common/services/Http';
import { HospitalStatusDto } from './models/HospitalStatusDto';

class HospitalStatusService {
    public async getStatus(url: string) {
        try {
            return await Http.get<HospitalStatusDto | null>(url);
        } catch (error) {
            return null;
        }
    }
}

export default new HospitalStatusService();
