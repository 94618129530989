import React from 'react';
import styles from './NoInfoToShow.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
    className?: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const NoInfoToShow: React.FC<Props> = ({ className, children, ...props }: Props) => {
    const { t } = useTranslation();
    return (
        <div
            {...props} className={`${styles.container} ${className ?? ''}`}
        >
            {t('common.messages.no_info_to_show')}
        </div>
    );
}

export default NoInfoToShow;
