import React from 'react';
import styles from './PolesLegend.module.scss';
import { PoleDto } from 'api/poles/models/PoleDto';

interface Props {
    poles: PoleDto[];
}

const PolesLegend: React.FC<Props> = ({ poles }: Props) => (
    <div className={styles.container}>
        {poles.map((pole) => (
            <div key={pole.id} className={styles.item}>
                <div className={styles.rectangle} style={{ backgroundColor: pole.color }} />
                <div className={styles.label}>
                    {pole.name}
                </div>
            </div>
        ))}
    </div>
);

export default PolesLegend;
