import React, { PureComponent } from 'react';
import Events from '../../services/Events';
import LoadingSpinner from './LoadingSpinner';
import styles from './LoadingProvider.module.scss';

type Props = {
}

interface State {
    showLoading: boolean;
}

class LoadingProvider extends PureComponent<Props, State> {

    state: State = {
        showLoading: false
    }
    
    private isComponentMounted = false;

    constructor(props: Props) {
        super(props);

        Events.addListener('loading', (loading: boolean) => {
            if (this.isComponentMounted && this.state.showLoading != loading) {
                this.setState({ showLoading: loading });
            }
        });
    }

    componentDidMount() {
        this.isComponentMounted = true;
    }

    componentWillUnmount() {
        this.isComponentMounted = false;
    }

    render() {
        if (this.state.showLoading) {
            return (
                <div className={styles.container}>
                    <LoadingSpinner />
                </div>
            );
        }
        else {
            return null;
        }
    }
}

export default LoadingProvider;
