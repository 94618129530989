import Http from 'common/services/Http';
import { PolesSearchCriteria } from './models/PolesSearchCriteria';
import { PoleDto } from './models/PoleDto';
import { UpdatePolesViewModel } from './models/UpdatePolesViewModel';

class PolesService {
    public getList(criteria: PolesSearchCriteria) {
        return Http.get<PoleDto[]>('poles/list', criteria);
    }

    public getLastSyncDate() {
        return Http.get<Date | null>('poles/last-sync-date');
    }

    public update(model: UpdatePolesViewModel) {
        return Http.put('poles', model);
    }
}

export default new PolesService();
