import useTitle from 'common/hooks/useTitle';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './HomePage.module.scss';
import PageHeader from 'common/components/pageHeader/PageHeader';
import PageFooter from 'common/components/pageFooter/PageFooter';
import PageBody from 'common/components/pageBody/PageBody';
import PolesTable, { PoleModel, PolePageModel } from './polesTable/PolesTable';
import PolesService from 'api/poles/PolesService';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import PolesLegend from './polesLegend/PolesLegend';
import NoInfoToShow from 'common/components/noInfoToShow/NoInfoToShow';
import LoadingSpinner from 'common/components/loading/LoadingSpinner';
import StatusBadge, { StatusBadgeColor } from 'common/components/statusBadge/StatusBadge';
import HospitalStatusService from 'api/hospitalStatus/HospitalStatusService';
import { HospitalStatus, HospitalStatusDto } from 'api/hospitalStatus/models/HospitalStatusDto';
import SettingsService from 'api/settings/SettingsService';
import { generatePolesHash, mapPolesPerPages } from './HomeUtils';

const DEFAULT_PAGE_DELAY = 15; // In seconds

interface Props {
    isTv?: boolean;
}

const HomePage: React.FC<Props> = ({ isTv }: Props) => {
    const { t } = useTranslation();
    useTitle(t('home.page_title', { appName: t('app.name') }));
    const [poles, setPoles] = useState<PoleModel[]>([]);
    const [polesPages, setPolesPages] = useState<PolePageModel[]>([{ delay: DEFAULT_PAGE_DELAY, list: [] }]);
    const [isLoading, setIsLoading] = useState(true);
    const [lastSyncDate, setLastSyncDate] = useState<Date | null>(null);
    const [hospitalStatus, setHospitalStatus] = useState<HospitalStatusDto | null>(null);
    const [currentPage, setCurrentPage] = useState(0);
    const lastPolesHash = useRef<string | null>(null);
    const hospitalStatusUrl = useRef<string | null>(null);

    const getData = async (showLoading = true) => {
        try {
            if (showLoading) {
                setIsLoading(() => true);
            }

            const [polesResult, lastSyncDateResult, settingsResult] = await Promise.all([
                PolesService.getList({
                    isVisibleOnly: isTv,
                }),
                PolesService.getLastSyncDate(),
                SettingsService.getWebsiteSettings(),
            ]);

            const polesHash = generatePolesHash(polesResult);
            if (!lastPolesHash.current || polesHash !== lastPolesHash.current) {
                lastPolesHash.current = polesHash

                setPoles(() =>
                    polesResult.filter(pole => !pole.isPageBreak).map(pole => ({
                        ...pole,
                        services: pole.services.filter(service => !service.isPageBreak),
                        allServices: pole.services.filter(service => !service.isPageBreak),
                    }))
                );
                setPolesPages(() => mapPolesPerPages(polesResult, DEFAULT_PAGE_DELAY));
            }

            hospitalStatusUrl.current = settingsResult?.statusUrl ?? null;
            
            setLastSyncDate(() => lastSyncDateResult ? new Date(lastSyncDateResult) : null);

            if (hospitalStatusUrl.current) {
                const hospitalStatusResult = await HospitalStatusService.getStatus(hospitalStatusUrl.current);
                setHospitalStatus(hospitalStatusResult ?? null);
            }

            if (showLoading) {
                setIsLoading(() => false);
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get management data', error);
            setIsLoading(() => false);
        }
    }

    useEffect(() => {
        void getData();
    }, []);

    useEffect(() => {
        if (!isTv) {
            return;
        }

        const timer = setInterval(() => {
            setCurrentPage((currPage) => {
                const newPage = currPage + 1;
                if (newPage > polesPages.length - 1) {
                    return 0;
                }
                return newPage;
            });

            void getData(false);
        }, polesPages[currentPage].delay * 1000);
        
        return () => {
            clearInterval(timer);
        }
    });

    useEffect(() => {
        if (isTv) {
            return;
        }

        const timer = setInterval(() => {
            void getData(false);
        }, 60000);

        return () => {
            clearInterval(timer);
        }
    });

    let statusLabel = null;
    let statusColor: StatusBadgeColor = StatusBadgeColor.green;
    if (hospitalStatus?.sau_adulte === HospitalStatus.ETAT_SAU_FERME) {
        statusLabel = t('home.status.closed');
        statusColor = StatusBadgeColor.red;
    }
    else if (hospitalStatus?.sau_adulte === HospitalStatus.ETAT_SAU_OUVERT) {
        statusLabel = t('home.status.open');
        statusColor = StatusBadgeColor.green;
    }
    else if (hospitalStatus?.sau_adulte === HospitalStatus.ETAT_SAU_TENSION) {
        statusLabel = t('home.status.tension');
        statusColor = StatusBadgeColor.orange;
    }

    const polesToList = !isTv ? poles : polesPages[currentPage].list;

    return (
        <div className={styles.container}>
            <PageHeader
                title={t('home.title')}
                rightContent={statusLabel ? (<StatusBadge text={t('home.status_label') + statusLabel} color={statusColor} />) : null}
            />
            <PageBody className={styles.body}>
                {isLoading && <div className={styles.loadingContainer}><LoadingSpinner /></div>}

                {!isLoading && !polesToList.length && <NoInfoToShow />}
                
                {Boolean(polesToList.length && !isLoading) && <PolesTable poles={polesToList} isTv={isTv} />}
                
                <PolesLegend poles={polesToList} />
            </PageBody>
            <PageFooter lastUpdatedDate={lastSyncDate} />
        </div>
    );
};

export default HomePage;
