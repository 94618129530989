import React from 'react';
import styles from './Item.module.scss';
import { FaBars, FaCaretDown, FaCaretUp, FaLevelUpAlt, FaLevelDownAlt } from 'react-icons/fa';
import Checkbox from 'common/components/checkbox/Checkbox';
import { hexToRgbA } from 'common/utils/hexToRgba';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { Menu, Item as MenuItem, useContextMenu } from 'react-contexify';
import 'react-contexify/ReactContexify.css';
import IconWithText from 'common/components/iconWithText/IconWithText';

export enum PageBreakDirection {
    BEFORE,
    AFTER,
}

interface Props {
    id: string;
    index: number;
    name: string;
    color: string;
    selected: boolean;
    isParent: boolean;
    isOpen?: boolean;
    children?: React.ReactNode;
    dragDisabled: boolean;
    onSelect: (selected: boolean) => void;
    onToggleIsOpen?: (isOpen: boolean) => void;
    onNewPageBreak: (direction: PageBreakDirection) => void;
}

const Item: React.FC<Props> = ({ id, index, name, color, selected, isParent, children, isOpen, onSelect, onToggleIsOpen, dragDisabled, onNewPageBreak }: Props) => {
    const { t } = useTranslation();
    const contextMenuId = `ContextMenu__${id}`;
    const { show } = useContextMenu({
        id: contextMenuId,
    });

    return (
        <Draggable draggableId={`item_`+ id} index={index} isDragDisabled={dragDisabled}>
            {provided => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                >
                    <div className={styles.outsideContainer} onContextMenu={event => show({ event })}>
                        <Checkbox
                            checked={selected}
                            className={styles.checkbox}
                            onChange={e => onSelect(e.target.checked)}
                        />
                        <div
                            className={`${styles.container} ${isParent ? styles.containerParent : ''}`}
                            style={{
                                borderColor: isParent ? color : undefined,
                                backgroundColor: selected ? hexToRgbA(color, 0.2) : undefined,
                            }}
                        >
                            {!dragDisabled && <div className={styles.dragHandle} {...provided.dragHandleProps}>
                                <FaBars className={styles.dragIcon} color={selected ? hexToRgbA(color, 0.4) : undefined} />
                            </div>}

                            <div className={styles.name} onClick={onToggleIsOpen ? () => onToggleIsOpen(!isOpen) : undefined}>
                                {name}
                            </div>
                            
                            {isParent && (
                                <div className={styles.toggleButton} onClick={onToggleIsOpen ? () => onToggleIsOpen(!isOpen) : undefined}>
                                    {isOpen ? <FaCaretUp /> : <FaCaretDown />}
                                </div>
                            )}
                        </div>
                    </div>

                    <Menu id={contextMenuId}>
                        <MenuItem id="page-break-up" onClick={() => onNewPageBreak(PageBreakDirection.BEFORE)}>
                            <IconWithText icon={<FaLevelDownAlt style={{ transform: 'scale(-1, -1)' }} />} text={t('management.new_page_break_before')} />
                        </MenuItem>
                        <MenuItem id="page-break-down" onClick={() => onNewPageBreak(PageBreakDirection.AFTER)}>
                            <IconWithText icon={<FaLevelUpAlt style={{ transform: 'scale(-1, -1)' }} />} text={t('management.new_page_break_after')} />
                        </MenuItem>
                    </Menu>

                    {isOpen && <div className={styles.children}>
                        {children}
                    </div>}
                </div>
            )}
        </Draggable>
    );
};

export default Item;
