import React from 'react';
import styles from './SearchBar.module.scss';
import { FaArrowRight, FaTimes } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

type Props = {
    value?: string;
    placeholder?: string | null;
    className?: string;
    onChange: (value: string) => void;
    onSearch?: () => void;
    onClear?: () => void;
    totalResults?: number;
    showResults?: boolean;
};

const SearchBar: React.FC<Props> = ({ value, placeholder, className, onChange, onSearch, onClear, totalResults, showResults }: Props) => {
    const { t } = useTranslation();

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && onSearch) {
            onSearch();
        }
    }

    return (
        <div className={`${styles.container} ${className ?? ''}`}>
            <input
                type="search"
                value={value}
                placeholder={placeholder ?? undefined}
                onChange={e => {
                    onChange(e.currentTarget.value)
                }}
                className={styles.input}
                onKeyDown={handleKeyDown}
            />
            {!!showResults && !!totalResults && !!value && totalResults > 0 &&
                <div className={styles.labelCloseContainer}>
                    {totalResults + ' ' + t('management.results_found')}
                </div>
            }

            {!!value && <div className={styles.iconCloseContainer} onClick={() => onClear ? onClear() : undefined}>
                <FaTimes className={styles.icon} />
            </div>}

            <div className={styles.iconContainer} onClick={onSearch}>
                <FaArrowRight className={styles.icon} />
            </div>
        </div>
    );
}

export default SearchBar;
