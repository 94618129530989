import { ReactNode } from 'react';
import styles from './IconWithText.module.scss';

interface Props {
    text: string;
    icon: ReactNode;
    className?: string;
}

const IconWithText = ({ text, icon, className }: Props) => (
    <div className={`${styles.container} ${className ?? ''}`}>
        {icon}
        <span className={styles.text}>{text}</span>
    </div>
)

export default IconWithText;