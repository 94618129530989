import Http from 'common/services/Http';
import { WebsiteSettingsDto } from './models/WebsiteSettingsDto';

class SettingsService {
    public async getWebsiteSettings() {
        return await Http.get<WebsiteSettingsDto>(`settings/website`);
    }
}

export default new SettingsService();
