import NotFoundPage from 'pages/errors/NotFoundPage';
import HomePage from 'pages/home/HomePage';
import ManagementPage from 'pages/managment/ManagementPage';
import { Navigate, Route, Routes, BrowserRouter } from 'react-router-dom';

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/tv" element={<HomePage isTv />} />
                <Route path="/management" element={<ManagementPage />} />
                <Route path="/404" element={<NotFoundPage />} />
                <Route path="*" element={<Navigate replace={true} to="/404" />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
