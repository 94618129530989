import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { PoleDto } from 'api/poles/models/PoleDto';
import Item, { PageBreakDirection } from '../item/Item';
import { PoleServiceDto } from 'api/poles/models/PoleServiceDto';
import PageBreakItem from '../pageBreakItem/PageBreakItem';

interface Props {
    pole: PoleDto;
    dragDisabled: boolean;
    onSelect: (pole: PoleDto, service: PoleServiceDto, selected: boolean) => void;
    onNewPageBreak: (pole: PoleDto, service: PoleServiceDto, direction: PageBreakDirection) => void;
    onRemovePageBreak: (pole: PoleDto, pageBreak: PoleServiceDto) => void;
    onChangePageBreak: (pole: PoleDto, pageBreak: PoleServiceDto, pageBreakDelay: number) => void;
}

const PoleServicesList: React.FC<Props> = ({ pole, dragDisabled, onSelect, onNewPageBreak, onRemovePageBreak, onChangePageBreak }: Props) => {
    return (
        <Droppable droppableId={`SERVICES__` + pole.id} isDropDisabled={dragDisabled} type={`SERVICES`}>
            {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                    {pole.services.map((service, serviceIndex) => {
                        if (service.isPageBreak) {
                            return (
                                <PageBreakItem
                                    key={service.id}
                                    id={service.id}
                                    index={serviceIndex}
                                    pageBreakDelay={service.pageBreakDelay}
                                    dragDisabled={dragDisabled}
                                    onRemove={() => onRemovePageBreak(pole, service)}
                                    onNewPageBreak={direction => onNewPageBreak(pole, service, direction)}
                                    onChange={(pageBreakDelay) => onChangePageBreak(pole, service, pageBreakDelay)}
                                />
                            );
                        }
                        return (
                            <Item
                                key={service.id}
                                id={service.id}
                                index={serviceIndex}
                                name={service.name + ' (' + service.code+')'}
                                color={service.color ?? ''}
                                isParent={false}
                                selected={service.visible}
                                onSelect={selected => onSelect(pole, service, selected)}
                                dragDisabled={dragDisabled}
                                onNewPageBreak={direction => onNewPageBreak(pole, service, direction)}
                            />
                        );
                    })}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
};

export default PoleServicesList;
