import React from 'react';
import styles from './PageBody.module.scss';

type Props = {
    children: any;
    className?: string;
};

const PageBody: React.FC<Props> = ({ children, className }: Props) => {
    return (
        <div className={`${styles.container} ${className ?? ''}`}>
            {children}
        </div>
    );
}

export default PageBody;
