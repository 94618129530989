import React, { useEffect, useState } from 'react';
import styles from './PageFooter.module.scss';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DATE_EXTENDED_FORMAT, TIME_FORMAT } from 'Config';

type Props = {
    lastUpdatedDate: Date | null;
    className?: string;
};

const PageFooter: React.FC<Props> = ({ lastUpdatedDate, className }: Props) => {
    const { t } = useTranslation();
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => setDate(new Date()), 1000);
        return () => {
            clearInterval(timer);
        }
    });

    return (
        <div className={`${styles.container} ${className ?? ''}`}>
            <div className={styles.date}>
                {dayjs(date).format(DATE_EXTENDED_FORMAT)}
            </div>
            <div className={styles.time}>
                {dayjs(date).format(TIME_FORMAT)}
            </div>
            <div className={styles.lastUpdated}>
                {lastUpdatedDate && `${t('common.last_updated')} ${dayjs(lastUpdatedDate).format(DATE_EXTENDED_FORMAT)} ${dayjs(lastUpdatedDate).format(TIME_FORMAT)}`}
            </div>
        </div>
    );
}

export default PageFooter;
