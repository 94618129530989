import { PoleDto } from "api/poles/models/PoleDto";
import { PolePageModel } from "./polesTable/PolesTable";

export function mapPolesPerPages(poles: PoleDto[], defaultPageBreakDelay: number): PolePageModel[] {
    const result: PolePageModel[] = [
        {
            delay: defaultPageBreakDelay, // Assigned when found the first page break 
            list: [],
        }
    ];

    for (const pole of poles) {
        if (pole.isPageBreak) {
            result[result.length - 1].delay = pole.pageBreakDelay ?? defaultPageBreakDelay;
            result.push({
                delay: defaultPageBreakDelay, // Assigned when found the next page break 
                list: [],
            });
        }
        else {
            result[result.length - 1].list.push({
                ...pole,
                services: [],
                allServices: pole.services,
            });

            for (let i = 0; i < pole.services.length; i++) {
                const service = pole.services[i];

                if (service.isPageBreak) {
                    const isLast = (i + 1) <= pole.services.length - 1;

                    if (isLast) {
                        result[result.length - 1].list[result[result.length - 1].list.length - 1].isVirtual = true;
                    }

                    result[result.length - 1].delay = service.pageBreakDelay ?? defaultPageBreakDelay;
                    result.push({
                        delay: defaultPageBreakDelay, // Assigned when found the next page break 
                        list: [],
                    });

                    // If there is more
                    if (isLast) {

                        result[result.length - 1].list.push({
                            ...pole,
                            services: [],
                            allServices: pole.services,
                        });
                    }
                }
                else {
                    result[result.length - 1].list[result[result.length - 1].list.length - 1].services.push(service);
                }
            }
        }
    }

    console.log('result', result.filter(x => x.list.length > 0));

    return result.filter(x => x.list.length > 0);
}

export function generatePolesHash(poles: PoleDto[]): string {
    return JSON.stringify(poles);
}
