import i18n, { type InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { DEFAULT_LANGUAGE, DEFAULTNS } from '../../Config';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const config: InitOptions = {
    fallbackLng: DEFAULT_LANGUAGE,
    debug: false,
    initImmediate: false,
    load: 'languageOnly',
    ns: [DEFAULTNS],
    defaultNS: DEFAULTNS,
    interpolation: {
        escapeValue: false
    },
    react: {
        useSuspense: false
    },
    backend: {
        loadPath: `${process.env.PUBLIC_URL}/translations/{{lng}}.json`
    },
};

void i18n
    .use(LanguageDetector)
    .use(HttpBackend)
    .use(initReactI18next)
    .init(config);

export default i18n;
