import React from 'react';
import styles from './PageBreakItem.module.scss';
import { FaBars, FaLevelDownAlt, FaLevelUpAlt, FaTrash } from 'react-icons/fa';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { Menu, Item as MenuItem, useContextMenu, Separator } from 'react-contexify';
import IconWithText from 'common/components/iconWithText/IconWithText';
import { PageBreakDirection } from '../item/Item';

type ContentProps = Props & { provided: DraggableProvided | null };

const PageBreakContent: React.FC<ContentProps> = ({ id, dragDisabled, pageBreakDelay, disableContextMenu, provided, onRemove, onNewPageBreak, onChange }: ContentProps) => {
    const { t } = useTranslation();
    const contextMenuId = `ContextMenu_PB__${id}`;
    const { show } = useContextMenu({
        id: contextMenuId,
    });

    return (
        <div>
            <div className={styles.outsideContainer} onContextMenu={event => !disableContextMenu ? show({ event }) : undefined}>
                <div className={styles.splitIcon}></div>
                <div className={`${styles.container}`}>
                    {!dragDisabled && <div className={styles.dragHandle} {...(provided ? provided.dragHandleProps : {})}>
                        <FaBars className={styles.dragIcon} />
                    </div>}

                    <div className={styles.pageDelayContainer}>
                        <input
                            type="number"
                            placeholder="0"
                            className={styles.pageDelayInput}
                            value={pageBreakDelay ?? 0}
                            onChange={(e) => {
                                if (onChange) {
                                    let val = e.target.valueAsNumber;
                                    if (val < 0) {
                                        val = 0;
                                    }
                                    onChange(val);
                                }
                            }}
                        />
                        <span className={styles.pageDelayLabel}>{t('management.page_break_delay')}</span>
                    </div>

                    <div className={`${styles.name} ${!dragDisabled ? styles.draggable : ''}`}>
                        {t('management.page_break')}
                    </div>

                    <div className={styles.leftSide}></div>
                    <div className={styles.rightSide}></div>
                </div>
            </div>
            <Menu id={contextMenuId}>
                <MenuItem
                    id="page-break-up"
                    onClick={() => {
                        if (onNewPageBreak) {
                            onNewPageBreak(PageBreakDirection.BEFORE);
                        }
                    }}
                >
                    <IconWithText icon={<FaLevelDownAlt style={{ transform: 'scale(-1, -1)' }} />} text={t('management.new_page_break_before')} />
                </MenuItem>
                <MenuItem
                    id="page-break-down"
                    onClick={() => {
                        if (onNewPageBreak) {
                            onNewPageBreak(PageBreakDirection.AFTER);
                        }
                    }}
                >
                    <IconWithText icon={<FaLevelUpAlt style={{ transform: 'scale(-1, -1)' }} />} text={t('management.new_page_break_after')} />
                </MenuItem>
                <Separator />
                <MenuItem id="page-break-remove" onClick={onRemove}>
                    <IconWithText icon={<FaTrash />} text={t('management.page_break_remove')} className={styles.menuItemRemove} />
                </MenuItem>
            </Menu>
        </div>
    );
}

interface Props {
    id: string;
    index: number;
    pageBreakDelay: number | null;
    dragDisabled: boolean;
    isOutsideDraggable?: boolean;
    disableContextMenu?: boolean;
    onRemove?: () => void;
    onNewPageBreak?: (direction: PageBreakDirection) => void;
    onChange?: (pageBreakDelay: number) => void;
}

const PageBreakItem: React.FC<Props> = (props: Props) => {
    if (props.isOutsideDraggable) {
        return <PageBreakContent {...props} provided={null} />;
    }

    return (
        <Draggable draggableId={`PAGE_BREAK__`+ props.id} index={props.index} isDragDisabled={props.dragDisabled}>
            {provided => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                >
                    <PageBreakContent {...props} provided={provided} />
                </div>
            )}
        </Draggable>
    );
};

export default PageBreakItem;
