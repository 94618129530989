import React from 'react';
import styles from './StatusBadge.module.scss';
import { FaInfoCircle } from "react-icons/fa";

export enum StatusBadgeColor {
    red = 'red',
    green = 'green',
    orange = 'orange',
}

type Props = {
    text: string;
    color?: StatusBadgeColor;
    className?: string;
};

const StatusBadge: React.FC<Props> = ({ text, className, color = StatusBadgeColor.green }: Props) => (
    <div className={`${styles.container} ${styles[color]} ${className ?? ''}`}>
        <FaInfoCircle />
        <span className={styles.text}>
            {text}
        </span>
    </div>
)

export default StatusBadge;
