
/**
 * API
 */
export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const SERVER_BASE_URL = process.env.REACT_APP_SERVER_URL;

/**
 * Storage
 */

export const STORAGE_KEY = 'cQfTjWnZr4u7x!A%D*G-KaNdRggUkXp2s';
export const STORAGE = {
    CURRENT_LOCALE: 'CURRENT_LOCALE',
    AUTH_USER_TOKEN: 'AUTH_USER_TOKEN',
    AUTH_USER_PROFILE: 'AUTH_USER_PROFILE',
};

/**
 * Logger
 */
export enum LOGGER_MESSAGE_TYPE {
    INFO = 1,
    ERROR,
}

export enum LOGGER_LOG_TYPE {
    REQUEST,
    APP_CRASH
}

export const LOGGER_PRINT_LOGS = process.env.REACT_APP_LOGGER_PRINT_LOGS || false;
export const LOGGER_PRINT_LOGS_LEVEL = LOGGER_MESSAGE_TYPE.INFO;

/**
 * DATE FORMAT
 */
export const TIME_FORMAT = 'HH:mm:ss';
export const DATE_EXTENDED_FORMAT = 'dddd, DD MMMM YYYY';

/**
 * Http errors
 */
export const HTTP_ERRORS = {
    UNAUTHORIZED: 401,
    CONFLICT: 409
};

/**
 * Languages
 */
export const DEFAULT_LANGUAGE = 'fr';
export const DEFAULTNS = 'translations';

