import React from 'react';
import styles from './Checkbox.module.scss';
import { FaCheck } from 'react-icons/fa';

type Props = {
    checked: boolean;
    className?: string;
    disabled?: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
};

const Checkbox: React.FC<Props> = ({ checked, className, disabled, onChange }: Props) => (
    
    <label
        className={`${styles.container} ${className ?? ''}`}
        style={{ cursor: disabled ? 'not-allowed' : '' }}
    >
        <input
            type="checkbox"
            className={styles.input}
            checked={checked}
            onChange={onChange}
        />
        <span
            className={`${styles.checkMark} ${disabled ? styles.disabled : ''}`}
        >
            <FaCheck className={styles.icon} />
        </span>
    </label>
)

export default Checkbox;
