import React from 'react';
import styles from './Button.module.scss';

type Props = {
    className?: string;
    colorType?: 'solid' | 'outline';
    color?: 'primary';
    type?: "submit" | "reset" | "button";
    spaceRight?: boolean;
    size?: 'normal' | 'small';
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const Button: React.FC<Props> = ({ className, children, type = "button", colorType = 'solid', color = 'primary', spaceRight, size, ...props }: Props) => {
    return (
        <button
            {...props} 
            className={`${styles.button} ${styles[color]} ${styles[colorType]} ${className ?? ''} ${spaceRight ? styles.spaceRight : ''} ${styles[size ?? 'normal']} `}
        >
            {children}
        </button>
    );
}

export default Button;
