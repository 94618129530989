import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { PoleDto } from 'api/poles/models/PoleDto';
import Item, { PageBreakDirection } from '../item/Item';
import PageBreakItem from '../pageBreakItem/PageBreakItem';

interface Props {
    poles: PoleDto[];
    dragDisabled: boolean;
    children: (pole: PoleDto) => React.ReactNode;
    onSelect: (pole: PoleDto, selected: boolean) => void;
    onToggleIsOpen: (pole: PoleDto, isOpen: boolean) => void;
    onNewPageBreak: (pole: PoleDto, direction: PageBreakDirection) => void;
    onRemovePageBreak: (pageBreak: PoleDto) => void;
    onChangePageBreak: (pageBreak: PoleDto, pageBreakDelay: number) => void;
}

const PolesList: React.FC<Props> = ({ poles, dragDisabled, children, onSelect, onToggleIsOpen, onNewPageBreak, onRemovePageBreak, onChangePageBreak }: Props) => {
    return (
        <Droppable droppableId="POLES" type="POLES">
            {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                    {poles.map((pole, poleIndex) => {
                        if (pole.isPageBreak) {
                            return (
                                <PageBreakItem
                                    key={pole.id}
                                    id={pole.id}
                                    index={poleIndex}
                                    pageBreakDelay={pole.pageBreakDelay}
                                    dragDisabled={dragDisabled}
                                    onRemove={() => onRemovePageBreak(pole)}
                                    onNewPageBreak={(direction) => onNewPageBreak(pole, direction)}
                                    onChange={(pageBreakDelay) => onChangePageBreak(pole, pageBreakDelay)}
                                />
                            );
                        }
                        return (
                            <Item
                                key={pole.id}
                                id={pole.id}
                                index={poleIndex}
                                name={pole.name ?? ''}
                                color={pole.color ?? ''}
                                isParent={true}
                                isOpen={pole.isOpen ?? false}
                                selected={pole.visible}
                                onSelect={selected => onSelect(pole, selected)}
                                onToggleIsOpen={isOpen => onToggleIsOpen(pole, isOpen)}
                                dragDisabled={dragDisabled}
                                onNewPageBreak={(direction) => onNewPageBreak(pole, direction)}
                            >
                                {children(pole)}
                            </Item>
                        );
                    })}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
};

export default PolesList;
