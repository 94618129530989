import React from 'react';
import styles from './PageHeader.module.scss';

type Props = {
    title: string;
    rightContent?: React.ReactNode;
};

const PageHeader: React.FC<Props> = ({ title, rightContent }: Props) => {
    return (
        <div className={styles.container}>
            <img src="imgs/logo.png" className={styles.image} />
            <div className={styles.title}>
                {title}
            </div>
            {rightContent && (
                <div className={styles.rightContent}>
                    {rightContent}
                </div>
            )}
        </div>
    );
}

export default PageHeader;
