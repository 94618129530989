import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    children: React.ReactElement
}

const Setup: React.FC<Props> = ({ children }: Props) => {
    const { i18n } = useTranslation();
    const [i18nLoaded, setI18nLoaded] = useState(false);
    
    useEffect(() => {
        if (i18n.isInitialized) {
            setI18nLoaded(true);
        }
    }, [i18n.isInitialized]);

    return i18nLoaded ? children : null;
};

export default Setup;
