import React from 'react';
import { I18nextProvider } from 'react-i18next';
import Router from 'Router';
import Setup from 'Setup';
import i18n from './common/services/I18n';
import { Toaster } from 'react-hot-toast';
import LoadingProvider from './common/components/loading/LoadingProvider';

const App: React.FunctionComponent = () => {
    return (
        <I18nextProvider i18n={i18n}>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <Setup>
                <Router />
            </Setup>
            <LoadingProvider />
        </I18nextProvider>
    );
};

export default App;
